.weekdays-row {
    display: flex;
    align-items: center;
  }
  
  .weekday {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  
  .color-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .weekday-name {
    font-size: 14px;
  }
  
  /* Custom colors for the dots */
  .color-dot:nth-child(1) {
    background-color: red;
  }
  
  .color-dot:nth-child(2) {
    background-color: orange;
  }
  
  .color-dot:nth-child(3) {
    background-color: yellow;
  }
  
  .color-dot:nth-child(4) {
    background-color: green;
  }
  
  .color-dot:nth-child(5) {
    background-color: blue;
  }
  
  .color-dot:nth-child(6) {
    background-color: indigo;
  }
  
  .color-dot:nth-child(7) {
    background-color: violet;
  }
  