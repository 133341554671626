.datatable-demo .p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td:last-child .p-column-title {
  display: none;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-details > .p-grid {
  border-radius: 3px;
  margin: 0.3em;
  text-align: center;
  padding: 2em 0 2.25em 0;
}
.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 24px;
}
.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-subtitle {
  margin: 0.25em 0 2em 0;
}
.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button {
  margin-left: 0.5em;
}
.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button:first-child {
  margin-left: 0;
}

.dataview-demo .p-dataview .car-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
}
.textAlignLeft{
  text-align: left !important;
}
.dataview-demo .p-dataview .car-details > div {
  display: flex;
  align-items: center;
}
.dataview-demo .p-dataview .car-details > div img {
  margin-right: 14px;
}
.dataview-demo .p-dataview .car-detail {
  padding: 0 1em 1em 1em;
  margin: 1em;
}

@media (max-width: 1024px) {
  .dataview-demo .p-dataview .car-details img {
    width: 75px;
  }

}

.signinForm__input {
  background-color: #fff !important;
  border: 1px solid #d1d5d9 !important;
  border-radius: 4px !important;
  
  display: block !important;
  font-size: 14px !important;
  height: 40px !important;
  padding: 8px !important;
  transition: border-color 0.25s ease, color 0.25s ease !important;
  width: 100% !important;
}
.login-panel{
  width: 470px !important;
}
.signinForm__input_label{
  float: left;
  margin-left: 4px;
}
.loginFont{
font-size: 26px;
font-weight: 700;
}
.p-sidebar.p-component.marginTop111.p-sidebar-left.p-sidebar-active {
  z-index: 1001008;
  padding-bottom: 84px;
  overflow: scroll;
}

.forgotPassForm__submit, .signinForm__submit, .signupForm__submit {
  background-color: #1973ba !important;
  border: 1px solid #1973ba !important;
  border-radius: 4px !important;
  color: #fff !important;
  display: block !important;
  font-size: 16px !important;
  height: 40px !important;
  transition: background-color 0.25s ease !important;
  width: 100% !important;
}
.signinForm__forgot {
  background-color: #e91e63 !important;
  border: 1px solid #e91e63 !important;
  border-radius: 4px !important;
  color: #fff !important;
  display: block !important;
  font-size: 16px !important;
  height: 40px !important;
  transition: background-color 0.25s ease !important;
  width: 100% !important;
}
