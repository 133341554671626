.marquee-container {
    overflow: hidden;
  }
  
  .marquee-content {
    white-space: nowrap;
    animation: marquee 15s linear infinite;
    font-size: 18px;
    font-weight: 100px;
    color: #161616;
    font-style: oblique;
  
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  