/* .p-datatable table {
    width:80% !important;
} */

.tableDiv{
    width: 30%;
}

.dataInfoDiv{
    width: 70%;
    display: flex;
    padding-left: 15px;
    text-align: center;
}

.tableDataInfoDiv{
    width: 100%;
    margin-top: 20px;
}

.titleDiv{
    height: 50px;
    font-size: 15px;
}
.textDiv{
    height: 150px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 50px;
}
