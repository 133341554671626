
.errorSpan {
    color: #e62a10;
    float: left;
    font-size: 11px
}

.merginLeft15 {
    margin-left: 15px;
}

.merginLeft30 {
    margin-left: 30px;
}

.borderRadius50 {
    border-radius: 50%;
}

.font21Weight500 {
    font-weight: 500;
    font-size: 21px;
}

.floatRight {
    float: right;
}

.floatLeft {
    float: left;
}

.noPaddingLeft {
    padding-left: 0 !important;
}

.noPaddingRight {
    padding-right: 0 !important;
}

.ui-datepicker {
    overflow: visible;
    z-index: 999;
}


.p-calendar {
    margin-top: 4px !important;
}

.p-fileupload {
    width: 100% !important;
}

.p-fileupload-buttonbar button:nth-child(2) {
    display: none !important;
}

.p-fileupload-buttonbar button:nth-child(3) {
    display: none !important;
}

.p-fileupload-row div:nth-child(2) {
    word-break: break-all;
}

.topR {
    top: 0px !important
}

.dlsplayInlineBlock {
    display: inline-block;
}
.horizontaldisplay{
    display: flex;
    justify-content: space-between;
}

.buttonWidth {
    width: 96px !important;
    margin-top: 6px !important;
    margin: 3px !important;
}

.imgButton {
    text-align: center !important;
}

.imageWidth100 {
    max-width: 100%;
    max-height: 250px;
    border-radius: 4px;
    border: 1px solid #ccc!important;
}

.imageHeight160 {
    max-width: 100%;
    max-height: 130px;
    border-radius: 4px;
    border: 1px solid #ccc!important;
}

.round {
    border-radius: 50%;
}

.dasbboardName {
    top: 21px;
    position: absolute;
    left: 64px;
    font-size: 15px;
    color: #212121;
}

.fontWeight600 {
    font-weight: 600;
}

.imageShadow {
    box-shadow: 2px 9px 5px #ccc;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid blue;
    border-bottom: 16px solid blue;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.tableHeader {
    font-weight: 600;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.p-dialog-content {
    overflow: visible !important;
}

.width420 {
    width: 420px !important;
}

#addServiceDialog,
#editServiceDialog {
    width: 420px !important;
}

.positionAbs{
    position: absolute;
    height: 1000px;
    overflow-x: auto;
}



.positionStatic {
    position: static !important;
}

.resize-drag {
    position: absolute !important;
    -ms-touch-action: none;
    touch-action: none;
}

.marginTop70 {
    margin-top: 70px;
}

#outer-dropzone {
    height: 140px;
    touch-action: none;
}

#QRcodeLabel {
    position: absolute;
    top: 381px;
    left: 30%;
}

#QRcodeXLabel {
    position: absolute;
    top: 381px;
    left: 51%;
}

#QRcodeYLabel {
    position: absolute;
    top: 381px;
    left: 72%;
}

#file-selector1 {
    display: inline;
    margin-left: 10px;
}

#templateLable {
    margin-top: 10px;
}

#showCoordinatesEL {
    width: 18%;
}

.width18 {
    width: 18%;
}
.width31{
    width: 31% !important
}
.widthAndheight {
    width: 50pt;
    height: 50pt;
    z-index: 200;
}
#pageContainer{
    height: 175px;
}

#QRcode {
    /* left: 396px; */
}

#QRcodeX {
    /* left: 51%; */
}
.textAlignCenter{
    text-align: center;
}

#QRcodeY {
    /* left: 72% */
}
.marginTop10{
    margin-top: 10px;
}
.marginTop111{
    margin-top: 111px;
}

.marginTop21{
    margin-top: 21px;
}
.marginTop12{
    margin-top: 12px;
}

.inputField {
    width: 70px;
    margin-top: 82px;
}

.inputField1 {
    width: 70px;
    margin-top: 92px;
}
.displayContents{
    display: contents;
}
.displayFlex{
    display: flex;
}
.width50{
    width: 49% !important;
}
.cordinateSystem {
    position: absolute;
    width: 206px;
    margin-left: 292px;
}
#the-canvas{
    position: absolute !important;
    margin-left: 1px;
}

.displayNone{
    display: none;
}
.displayBlock{
    display: block;
}
.width111{
    width: 100px;
}
.arrow{
    width: 50px;
}
.inputHeight{
    height: 2.143em;
}

.marginRight10{
    margin-right: 10px;
}
.marginLeft10{
    margin-left: 10px;
}
#overlay {
        position: absolute;
}
#turn-off-button {
	position: absolute;
	top: 15px;
	left: 15px;
	width: 100px;
	padding: 5px;
	background: red;
	z-index: 9999px;
}


.marginRight20{
    margin-right: 20px;
}